import { render } from './render.client'
import reportWebVitals from './reportWebVitals'

// @ts-ignore
const preloadedState = window.__PRELOADED_STATE__ ?? '{}'

render({
  env: preloadedState.env,
  user: preloadedState.user,
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

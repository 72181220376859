interface CreatePayload {
  alias: string | null
  password: string | null
  redirectUrl: string
}

export const create = async ({ alias, password, redirectUrl }: CreatePayload) => {
  const apiUrl = alias ? `/api/${alias}` : '/api'
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      password,
      redirectUrl,
    }),
  })
  return await response.json()
}

import React from 'react'

export interface EnvContextType {
  AUTH_LOGOUT_URL: string,
  AUTH_REDIRECT_URL: string,
  BASE_URL_AUTH: string,
  BASE_URL_CREATE: string,
  BASE_URL_READ: string,
}

const context = React.createContext<EnvContextType>({
  AUTH_LOGOUT_URL: '',
  AUTH_REDIRECT_URL: '',
  BASE_URL_AUTH: '',
  BASE_URL_CREATE: '',
  BASE_URL_READ: '',
})

export default context

import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { BsBoxArrowRight } from 'react-icons/bs'

import ShortenerForm from '../components/Shortener/ShortenerForm'
import EnvContext from '../context/Env'

function Shortener() {
  const { AUTH_LOGOUT_URL, BASE_URL_AUTH, BASE_URL_CREATE } = useContext(EnvContext)

  return (
    <>
      <Helmet>
        <title>Shortener</title>
      </Helmet>

      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <header className="mb-auto" />
        <main className="px-3">
          <h1>Shortener</h1>
          <ShortenerForm />
        </main>
        <footer className="mt-auto">
          <p>
            <a href={`${BASE_URL_AUTH}${AUTH_LOGOUT_URL}?next=${BASE_URL_CREATE}`} className="link-light">
              <BsBoxArrowRight className="bi me-2" />
              Sign out
            </a>
          </p>
        </footer>
      </div>
    </>
  )
}

export default Shortener

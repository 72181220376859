import React from 'react'
import { HelmetProps, HelmetProvider } from 'react-helmet-async'
import { AnonymousUser, AuthenticatedUser } from '@kalamba/shortener-utils-auth'

import IndexRoute from './routes/index'
import EnvContext, { EnvContextType } from './context/Env'

export interface Props {
  env: EnvContextType
  helmetContext?: { helmet?: HelmetProps }
  user: AnonymousUser | AuthenticatedUser
}

function App({ env, helmetContext, user }: Props) {
  return (
    <React.StrictMode>
      <HelmetProvider context={helmetContext}>
        <EnvContext.Provider value={env}>
          <IndexRoute user={user} />
        </EnvContext.Provider>
      </HelmetProvider>
    </React.StrictMode>
  )
}

export default App

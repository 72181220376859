import React, { useCallback, useContext, useRef, useState } from 'react'
import trim from 'lodash/trim'

import EnvContext from '../../context/Env'
import { create } from '../../services/api'

const CHARACTERS_TO_TRIM = `!"#$%&'()*+,-./@:;<=>[\\]^_\`{|}~`

function useApiCall<Payload extends any>(
  apiCall: (payload: Payload) => any,
  { setErrors }: { setErrors: (errors: Record<string, string>[] | undefined) => void }
) {
  const [isFetching, setIsFetching] = useState(false)

  const call = useCallback(
    async (payload: Payload) => {
      try {
        setIsFetching(true)
        return await apiCall(payload)
      } catch (e) {
        setErrors([{ error: 'unknown' }])
      } finally {
        setIsFetching(false)
      }
    },
    [apiCall, setErrors]
  )

  return { call, isFetching }
}

function ShortenerForm() {
  const { BASE_URL_READ } = useContext(EnvContext)
  const formRef = useRef<HTMLFormElement>(null)
  const urlRef = useRef<HTMLInputElement>(null)
  const [redirectUrl, setRedirectUrl] = useState('')
  const [errors, setErrors] = useState<Record<string, string>[] | undefined>()
  const { call: shorten, isFetching } = useApiCall(create, { setErrors })
  const handleSubmit = useCallback(
    async (form: HTMLFormElement | null) => {
      if (!form) {
        return
      }

      const formData = new FormData(form)
      const redirectUrl = trim(formData.get('url') as string, CHARACTERS_TO_TRIM)
      if (!redirectUrl) {
        setErrors([{ redirectUrl: 'required' }])
        return
      }
      const alias = formData.get('alias') as string | null
      const password = formData.get('password') as string | null

      const response = await shorten({
        alias,
        password,
        redirectUrl,
      })
      if (response.errors) {
        setErrors(response.errors)
      } else {
        setRedirectUrl(response.link)
        setButtonText('Copy')
        setButtonClass('btn-primary')
        if (urlRef.current) {
          urlRef.current.value = response.link
          urlRef.current.select()
        }
      }
    },
    [shorten]
  )
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(redirectUrl)
    setButtonText('Copied!')
    setButtonClass('btn-success')
    window.setTimeout(() => {
      setButtonText('Copy')
      setButtonClass('btn-primary')
    }, 3000)
  }, [redirectUrl])

  const [buttonText, setButtonText] = useState('Shorten')
  const [buttonClass, setButtonClass] = useState('btn-secondary')
  const handleButtonClick = useCallback(
    (e: any) => {
      e.preventDefault()
      if (buttonText === 'Copy') {
        handleCopy()
      } else if (buttonText === 'Shorten') {
        handleSubmit(formRef.current)
      }
    },
    [buttonText, handleCopy, handleSubmit]
  )

  return (
    <form id="form" ref={formRef}>
      <div className="mb-3">
        <p className="lead">
          <label className="form-label" htmlFor="url">
            Shorten your link
          </label>
        </p>
        <input
          className={`form-control form-control-lg ${!!errors ? 'is-invalid' : ''}`}
          id="url"
          onChange={() => setErrors(undefined)}
          name="url"
          placeholder="https://example.com/such-long-link-12345.html?utm_source=google"
          ref={urlRef}
          type="text"
        />
        {errors && (
          <div className="invalid-feedback">
            {errors.map((error: Record<string, string>) =>
              Object.keys(error)
                .map(field => `${field} is ${error[field]}`)
                .join(', ')
            )}
          </div>
        )}
      </div>
      <input
        className={`btn btn-lg fw-bold ${buttonClass}`}
        disabled={!!errors || isFetching}
        onClick={handleButtonClick}
        type="button"
        value={buttonText}
      />
      <div className="mb-3 mt-5">
        <h2 className="">Options</h2>
        <div className="text-start">
          <div className="mb-3">
            <label htmlFor="alias">Alias</label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon3">
                {BASE_URL_READ}/
              </span>
              <input
                className="form-control"
                id="alias"
                name="alias"
                onChange={() => setErrors(undefined)}
                placeholder="your-custom-alias"
                type="text"
              />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="password">Password</label>
            <input className="form-control" id="password" name="password" placeholder="passw0rd" type="password" />
          </div>
        </div>
      </div>
    </form>
  )
}

export default ShortenerForm

import React from 'react'
import { AnonymousUser, AuthenticatedUser } from '@kalamba/shortener-utils-auth'

import Login from './Login'
import Shortener from './Shortener'

function IndexRoute({ user }: { user: AnonymousUser | AuthenticatedUser }) {
  return user.isAuthenticated ? <Shortener /> : <Login />
}

export default IndexRoute

import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { BsGoogle } from 'react-icons/bs'

import EnvContext from '../context/Env'

function Login() {
  const { AUTH_REDIRECT_URL, BASE_URL_AUTH, BASE_URL_CREATE } = useContext(EnvContext)

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>

      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <header className="mb-auto" />
        <main className="px-3">
          <a className="btn btn-lg btn-danger" href={`${BASE_URL_AUTH}${AUTH_REDIRECT_URL}?next=${BASE_URL_CREATE}`}>
            <BsGoogle className="bi me-2" />
            Sign in with Google
          </a>
        </main>
        <footer className="mt-auto" />
      </div>
    </>
  )
}

export default Login
